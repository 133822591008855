
module.exports = global.env = {
    SERVER_MEDIA_PATH: "/assets/",
    // SERVER_REQUEST_PATH: "http://34.205.75.27:7777/",
    // SERVER_REQUEST_PATH: "api.researchtube.ai:7777/",
    SERVER_REQUEST_PATH: "https://api.researchtube.ai/",
    // SERVER_REQUEST_PATH: "http://98.80.128.152:5005/",
    // SERVER_REQUEST_PATH: "http://localhost:5005/",
    SERVER_SOCKET_PATH: //'wss://asyncgenerate.researchtube.ai:8443',
        'ws://98.80.128.152:6444',
    PAGE_SIZE: 4,
    SCROLLED_PAGE_SIZE: 40,
    HOME_PAGE_SIZE: 10,
    SUMMARY_EDIT_MAX: 10,
    MAX_FILE_SIZE: "50 MB",
    VIDEO_DURATION_LIMIT: 2000,
    SECTION_NUM_LIMIT: 2,
    SECTION_NO_SECONDS: 30,
    STANDARD_VIDEO_DURATION_LIMIT: 300,
    NO_WORDS_PER_MIN: 200,
    allowedPages: ["login", "sign-up", "Error", "terms",
        "confirm-email",
        "AccessDenied", "coming-soon", "private-content",
        //"Summary", "Poster", "ShortClip", "Slides", "Infographics",
        "reset-password", "forget-password"
        , "Landing", "videos"
    ],
    publicPages: [
        "videos", "canvas"
    ],
    allowedReq: ["login", "addtowaitinglist", "preview"
        , "signup", "interests", "checkUsername", "checkEmail", "sendEmail", "resetPassword", "countries"],
    publicReq: [
        //  "recentVideos", "getImage"
        // , "videos", "increaseView", "getLikedSaved", "materials", "comments",

    ]
}