import React, { useCallback, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import '../css/UploadPDF.css';
import axios from 'axios'
import UploadFile from '../../SharedComponents/js/UploadFile';
import { Modal } from 'react-bootstrap';
import GenerateTerms from './GenerateTerms';
const helper = require('../../utilities/helpers')
const env = require('../../config.env')

const DragDropUpload = (props) => {
    const [interests, getinterests] = useState([])
    const [selectedinterest, setselectedinterest] = useState()
    const [extracted_dois, setextracted_dois] = useState([])
    const [isUploaded, set_isUploaded] = useState(false)
    const [error_msg, seterror_msg] = useState('')
    const [showAlert, setshowAlert] = useState("hidden")
    const [selectedFile, setSelectedFile] = useState()
    const [is_by_author, setis_by_author] = useState(false)
    const [is_share, setis_share] = useState(false)
    const [gen_type, setgenType] = useState('free')
    const [voice_type, setvoice_type] = useState('Matthew')
    const [DOI, setDOI] = useState('')
    const [doc_id, setdoc_id] = useState('')
    const [customPermission, setcustomPermission] = useState(false)
    const [is_terms_accepted, settermsAccepted] = useState(false)

    const [showTermsModal, setshowTermsModal] = useState(false);

    const handleClose = () => setshowTermsModal(false);
    const handleShow = () => setshowTermsModal(true);

    useEffect(() => {
        let found = helper.checkPagePermission(props.permissions, "custom")
        setcustomPermission(!found)
        axios.get(env.SERVER_REQUEST_PATH + 'interests')
            .then((response) => {
                let data = response.data.data
                getinterests(data)
                setselectedinterest(data[0].id)
            }).catch(err => {
                props.handleErrors(err)
                console.error(`Error: ${err}`)
            })
    }, [])
    const oncheck = (e) => {
        settermsAccepted(e.target.checked)
    }
    const showError = (msg) => {
        seterror_msg(msg)
        setshowAlert("")
        setTimeout(() => {
            hideError();
        }, 3000);
    }
    const hideError = () => {
        seterror_msg('')
        setshowAlert("hidden")
    }

    const debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer =
                setTimeout(() => func.apply(context, args), delay);
        }
    }
    const onDOIChange = (event) => {
        setDOI(event.target.value)
    }

    const onSelectInterset = (e) => {
        setselectedinterest(parseInt(e.target.value))
    }
    const setUploadedFile = (file) => {
        setSelectedFile(file)
        set_isUploaded(false)
        if (file)
            uploadFileInChunks('gen', file)
    }
    const saveDoc = (docId) => {
        props.beginProcess()
        if (!docId)
            docId = doc_id
        try {
            axios.post(env.SERVER_REQUEST_PATH + 'saveDoc', {
                doc_id: docId, DOI: " ",//DOI,
                is_by_author: false,//is_by_author, 
                interest: 1//selectedinterest
            }).then((res) => {
                // props.submit_Upload(gen_type, voice_type, docId, DOI, selectedinterest, is_by_author, is_share)
                props.submit_Upload(docId)
            }).catch((error) => {
                props.handleErrors(error)
            })
        }
        catch (error) {
            props.handleErrors(error)
        }

    }
    async function uploadFileInChunks(type, file) {
        const chunkSize = 1024 * 1000; // 1MB
        const totalChunks = Math.ceil(file.size / chunkSize);
        props.restErrors()
        props.beginProcess()
        let docId = ''

        for (let i = 0; i < totalChunks; i++) {
            const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
            const formData = new FormData();
            formData.append("file", chunk);
            // formData.append("index", i);
            // formData.append("totalChunks", totalChunks);
            // formData.append("fileName", docId ? docId : '');

            let result = await axios.post(env.SERVER_REQUEST_PATH + "upload_chunk", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "index": i,
                    "totalChunks": totalChunks,
                    "fileName": docId ? docId : ''

                }
            });
            docId = result.data.doc_id ? result.data.doc_id : ''
        }

        // Final request to notify backend to merge chunks
        await axios.post(env.SERVER_REQUEST_PATH + "merge_file", {
            fileName: docId,
            totalChunks: totalChunks
        }).then((res) => {
            set_isUploaded(true)
            props.endProcess()
            setdoc_id(res.data.doc_id)
            // if (next === 'doi')
            //     getDOI(res.data.doc_id)
            // else
            // saveDoc(res.data.doc_id)
            props.submit_Upload(res.data.doc_id)

        }).catch((error) => {
            props.handleErrors(error)
            // console.error('Failed to submit files.');

        })

        console.log("Upload completed!");
    }

    const upload_doc = (next, file) => {
        props.restErrors()
        // if (next === 'gen')
        //     e.preventDefault()
        // // if (!selectedFile) {
        // //     showError("Please Upload Your File First")
        // //     return
        // // }
        // // if (next === 'gen' && isUploaded) {
        // //     saveDoc()
        // //     return
        // // }
        // // if (next === 'doi' && isUploaded) {
        // //     getDOI()
        // //     return
        // // }
        const formData = new FormData();
        formData.append("file", file)
        props.beginProcess()
        try {
            axios.post(env.SERVER_REQUEST_PATH + 'upload_doc', formData, {
                headers: { 'content-type': 'multipart/form-data' }
            }).then((res) => {
                set_isUploaded(true)
                props.endProcess()
                setdoc_id(res.data.doc_id)
                // if (next === 'doi')
                //     getDOI(res.data.doc_id)
                // else
                // saveDoc(res.data.doc_id)
                props.submit_Upload(res.data.doc_id)

            });
        } catch (error) {
            props.handleErrors(error)
            // console.error('Failed to submit files.');
        }
    }
    return (
        // <form onSubmit={(e) => upload_doc(e, 'gen')}>
        <div className='dragdrop-upload-container'>
            <div>
                {props.mode == "aud" && <div className='my-4'>
                    <h3 className='text-center'>
                        Let’s create an audio from your research article.
                    </h3>
                    <p className='text-center'>Choose a research article file, and see the magic happens!</p>
                </div>}
                {props.mode == "vid" && <div className='my-4'>
                    <h3 className='text-center'>
                        Let’s create a video from your research article.
                    </h3>
                    <p className='text-center'>Choose a research article file, and see the magic happens!</p>
                </div>}

                <UploadFile uploadFile={setUploadedFile}
                    dragdrop={true}
                    label={'Click here to upload your file or drag and drop.'}
                    formatText={`Suported formats: PDF`}
                    fileType={'.pdf'}
                    disabled={props.hasGenPermission ? false : true}
                    // fileType={['pdf']}
                    labelAfterUpload="Uploaded Successfully!"
                />
                <Alert key="danger" variant="danger" className='mt-2'
                    onAnimationEnd={() => hideError()} hidden={showAlert}>
                    {error_msg}
                </Alert>

                {/* <div className='d-flex justify-content-end mt-3'>
                        <p className='file-cancel mt-2'
                            onClick={() => {
                                props.Cancel(false)
                            }}>Cancel</p>
                        <Button disabled={props.isLoading ? 'disabled' : props.stop_process ? 'disabled' : ''}
                            className='w-25 gen-button ms-3' type='submit' variant="primary"
                        >
                            {props.mode != 'summ' ? 'Generate' : 'Summarize'}
                        </Button>


                    </div> */}
            </div>
        </div >
        // </form >
    );

}

export default DragDropUpload
